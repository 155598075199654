@import "styles/variables.scss";

.header {
  width: 100%;
  @include text;
  z-index: 999;
}
.wrapper {
  & .links {
    background-image: url("/assets/header-background.png");
    background-color: rgba($color: #000000, $alpha: 0.08);
    background-position: top -30px center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 36px 0 64px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include laptop {
      background-position: top 0 center;
      padding: 5% 0;
    }
    @include mobile {
      justify-content: center;
      align-items: center;
      padding: 1% 0;
    }
    & .social {
      display: flex;
      align-items: center;
      gap: 8px;
      min-width: 240px;

      @include laptop {
        min-width: 30%;
        gap: 0.635vw;
      }
      & > span {
        margin-right: 4px;
        @include laptop {
          margin-right: 0.317vw;
        }
      }
      & > a {
        display: block;
        border-radius: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.03);
        width: 42px;
        height: 32px;
        transition: background-color 0.3s;
        @include laptop {
          width: 3.333vw;
          height: 2.54vw;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        &:active {
          background-color: rgba(255, 255, 255, 0.2);
        }
        & > svg {
          width: 36%;
        }
      }
    }
    & .logo {
      & > a {
        display: block;
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.85;
        }
      }
      @include mobile {
        & img {
          height: 35px;
          width: 112px;
          object-fit: contain;
        }
      }
    }
    & .talk {
      min-width: 240px;
      @include laptop {
        min-width: 30%;
      }
      @include mobile {
        position: fixed;
        text-align: center;
        background-image: url("/assets/footer-background.png");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 0;
        width: 100%;
        padding: 20px 0;
        left: 50%;
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
        z-index: 100;
        font-size: 14px;
        & span {
          transform: rotate(90deg);
        }
      }
    }
    & button {
      text-align: left;
      &:hover {
        & span {
          &:first-child {
            transform: translateX(-5px);
          }
          &:last-child {
            transform: translateX(5px);
          }
        }
      }
      & > div {
        @include link-hover;
      }
      & > div > span {
        display: inline-block;
        transition: transform 0.3s;
        &:first-child {
          margin-right: 7px;
        }
        &:last-child {
          margin-left: 7px;
        }
      }
    }
    & a[href="#project"] {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;

      & > span {
        display: inline-flex;
        align-items: center;
        margin-right: 12px;
        transition: transform 0.4s;
        @include laptop {
          max-width: 100%;
        }
      }
      &:hover {
        & > span {
          transform: rotate(90deg);
        }
      }
    }
    & a {
      @include link-hover;
    }
    & img {
      @include laptop {
        width: 10vw;
      }
    }
  }
  & .navigation {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-top: 1px solid $text1;
    @include mobile {
      margin-top: 28px;
    }
    & a {
      padding-top: 20px;
      text-align: center;
      @include link-hover;
      text-transform: uppercase;
      &:nth-child(2) {
        border-right: 1px solid $text1;
        border-left: 1px solid $text1;
      }
      @include mobile {
        @include jb5;
        padding-top: 13px;
        letter-spacing: 0.18px;
      }
    }
  }
}

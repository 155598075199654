@import "styles/variables.scss";

.section {
  @include mobile {
    height: auto !important;
  }
  & .video_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    @include mobile {
      height: auto;
    }
  }
  & .image_blur {
    position: absolute;
    z-index: 0;
    top: -18.5%;
    left: -18.5%;
    max-height: 137%;
    height: 137% !important;
    max-width: 137%;
    width: 137%;
    object-fit: contain;
  }
  & video {
    position: relative;
    z-index: 1;
    background-color: $bg;
    -o-object-fit: cover;
    object-fit: cover;
    // width: 100%;
    // height: 100%;
    cursor: pointer;
  }
}

.wr {
  position: relative;
  &:hover {
    .rec {
      animation: size 0.8s ease-in-out alternate infinite;
    }
  }

  & video {
    mask-image: url("/assets/masks/mask.svg");
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-image: url("/assets/masks/mask.svg");
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    z-index: 1;
    @include mobile {
      aspect-ratio: 16/9;
      height: 60vw !important;
      // mask-size: contain;
      // -webkit-mask-size: contain;
      mask-image: url("/assets/masks/mask-mobile.svg") !important;
      -webkit-mask-image: url("/assets/masks/mask-mobile.svg") !important;
    }
  }
  & .label {
    position: absolute;
    border: 1px solid $text1;
    border-radius: 16px;
    top: 0;
    left: 0;
    padding: 6px 12px 6px 15px;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    @include laptop {
      padding: 1%;
    }
    @include mobile {
      padding: 3px 7px;
      @include text;
    }
    & > div {
      margin-right: 11px;
      width: 10px;
      height: 10px !important;
      background-color: $red;
      filter: blur(2.5px);
      -webkit-filter: blur(2.5px);
      -moz-filter: blur(2.5px);
      -o-filter: blur(2.5px);
      -ms-filter: blur(2.5px);
      @include laptop {
        width: 5px;
        height: 5px !important;
        margin-right: 2px;
      }
      @include mobile {
        margin-right: 6px;
      }
    }
    & > span {
      @include text;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
.caption {
  display: flex;
  gap: 36px;
  @include laptop {
    gap: 3vw;
  }
  @include mobile {
    gap: 18px;
    align-items: flex-end;
  }
  &_text {
    width: 35%;
    @include text;
    @include mobile {
      width: 60%;
      word-break: break-word;
    }
    & > p {
      @include text;
    }
  }
  &_tag {
    @include text;
    color: $text3;
    & > li:last-child {
      display: flex;
      gap: 1px 8px;
      @include mobile {
        flex-wrap: wrap;
      }
    }
  }
}

@keyframes size {
  from {
    transform: scale(100%);
  }

  to {
    transform: scale(40%);
  }
}

@import "styles/variables.scss";

.wr {
  margin-bottom: 179px;
  padding-top: 58px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 100px;
  position: relative;
  @include laptop {
    margin-bottom: 20vw;
    padding-top: 5vw;
    gap: 10vw;
  }
  @include mobile {
    padding-top: 40px;
    gap: 0;
    margin-bottom: 80px;
    grid-template-columns: 1fr;
  }
}

.nav {
  max-width: 282px;
  transition: transform 0.6s;
  @include laptop {
    width: 22vw;
  }
  @include mobile {
    width: 100%;
    max-width: 100%;
  }
}

.filter {
  height: fit-content;

  & h2 {
    margin-bottom: 16px;
    @include text;
    @include jb5;
    color: $text2;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    @include mobile {
      margin-bottom: 8px;
    }
  }
  &_categories {
    margin-bottom: 36px;
    @include mobile {
      margin-bottom: 29px;
      max-width: 70%;
    }
    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;

      & button {
        @include text;
        @include jb5;
        color: $text2;
        letter-spacing: 0.24px;
        padding: 6px 12px;
        border-radius: 51px;
        border: 1px solid $text2;
        @include laptop {
          padding: 0.5em;
        }
        &:hover,
        &:focus {
          border-color: transparent;
          background-color: $text4;
          color: rgba(28, 28, 28, 1);
        }
        &:active {
          background-color: $text2;
          color: $text2;
        }
      }
    }
    & .category_active {
      border-color: transparent;
      background-color: $text4;
      color: rgba(28, 28, 28, 1) !important;
    }
  }
  &_tags {
    margin-bottom: 64px;
    @include mobile {
      max-width: 70%;
      margin-bottom: 40px;
    }
    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 4px;

      & button {
        @include text;
        @include jb5;
        color: $text2;
        letter-spacing: 0.24px;
        padding: 4px 8px;
        transition: color 0.3s;
        @include laptop {
          padding: 0.5em;
        }
        &:hover {
          color: $text3;
        }
        &:active {
          color: $text2;
        }
      }
    }
    & .tag_active {
      color: $text4;
    }
  }
  &_button {
    width: 100%;
    padding: 26px 0;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    @include laptop {
      padding: 2vw 0;
    }
    @include mobile {
      color: rgba(255, 255, 255, 0.2) !important;
      padding: 19px 0;
      margin-bottom: 8px;
    }
  }
}

.video {
  & > h1 {
    width: 43%;
    margin-bottom: 72px;
    @include laptop {
      margin-bottom: 10vw;
    }
    @include mobile {
      max-height: 35px;
      margin: 0 auto 40px;
      width: 70%;
    }
  }
  & > .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 108px 36px;
    @include laptop {
      gap: 7vw 2vw;
    }
    @include mobile {
      grid-template-columns: 1fr;
      gap: 64px;
    }
    & > li {
      & div {
        height: 100% !important;
      }
      height: 232px;
      @include laptop {
        height: auto;
        aspect-ratio: 16/9;
      }
      @include mobile {
        height: auto !important;
        grid-column: initial !important;
        grid-row: initial !important;
        & img {
          border-radius: 16px;
        }
      }
      &:nth-child(3n) {
        height: 484px;
        grid-column: span 2;
        grid-row: span 2;
        @include laptop {
          height: auto;
          aspect-ratio: 16/9;
        }
      }
    }

    &_last_big {
      & > li:last-child {
        height: 484px;
        grid-column: span 2;
        grid-row: span 2;
        @include laptop {
          height: auto;
          aspect-ratio: 16/9;
        }
      }
    }

    &_4 {
      & > li {
        &:nth-child(3n) {
          grid-column: initial;
          grid-row: initial;
          height: initial;
        }
        &:nth-child(1),
        &:nth-child(4) {
          grid-column: span 2;
          grid-row: span 2;
          height: 484px;
          @include laptop {
            height: auto;
            aspect-ratio: 16/9;
          }
        }
      }
    }
    &_2 {
      & > li {
        height: 484px;
        grid-column: span 2;
        grid-row: span 2;
        @include laptop {
          height: auto;
          aspect-ratio: 16/9;
        }
      }
    }
  }
}

.button_mobile {
  margin-bottom: 58px;
  padding: 17px 0;
  width: 100%;
  border-radius: 12px;
  text-transform: uppercase;
}

.filters_mobile {
  & > div {
    height: 100%;
    padding: 28px 16px;
    background-color: #151515;
  }
  & header {
    padding-top: 10px;
    border-top: 1px solid $text1;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 28px;
  }
}

.reset_button {
  color: $text2;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.18px;
  margin: 0 auto;
  width: 100%;
}

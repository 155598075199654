@import "styles/variables.scss";

#contactForm {
  & .Mui-checked ~ span {
    color: #1c1c1c;
    background-color: $text4;
    border-color: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px $bg inset !important;
  -webkit-text-fill-color: white !important;
  @include text;
}

.tabs {
  position: relative;

  & .Mui-selected {
    color: $text6 !important;
  }
  & .MuiTabs-flexContainer {
    @include mobile {
      gap: 20px;
      padding-bottom: 16px;
    }
  }
  & .MuiTabs-scroller {
    @include mobile {
      height: min-content;
      border-bottom: 1px solid $text1;
    }
  }
  & .MuiTabs-scrollButtons {
    position: absolute;
    top: 0;
    height: 20px;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.15s;
    &:first-of-type {
      transform: translateX(-1px) translateY(-5%);
      background: linear-gradient(
        90deg,
        #151515 51.52%,
        rgba(21, 21, 21, 0) 100%
      );
      left: 0;
      justify-content: flex-start;
    }
    &:last-of-type {
      transform: translateX(1px) translateY(-10%);
      background: linear-gradient(
        -90deg,
        #151515 51.52%,
        rgba(21, 21, 21, 0) 100%
      );
      justify-content: flex-end;
      right: 0;
    }

    & svg {
      fill: #494949;
      width: auto;
      transform: scale(1.15, 1.3);
    }
  }
}

.image_modal {
  .MuiBackdrop-root {
    background-color: rgba($color: $bg, $alpha: 0.7);
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .MuiPaper-root {
    margin: 0;
    max-width: 70%;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    & img {
      border-radius: 24px;
      @include mobile {
        border-radius: 7px;
      }
    }
  }
}

.video_modal {
  .MuiBackdrop-root {
    background-color: rgba($color: $bg, $alpha: 0.7);
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .MuiPaper-root {
    margin: 0;
    max-width: 90%;
    width: 1244px;
    aspect-ratio: 16/9;
    background-color: $bg;
  }
  .MuiDialogContent-root {
    overflow-y: hidden;
  }
}

.swiper-scrollbar {
  height: 24px !important;
  border-radius: 8px;
  left: 273px !important;
  bottom: 0 !important;
  background-color: transparent;
  width: 708px !important;
  border: 0.55px solid rgba($color: #2e2e2e, $alpha: 0.8);
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    .swiper-scrollbar-drag {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  @include laptop {
    height: 1.9vw !important;
    left: 21.9vw !important;
    width: 50% !important;
  }
  @include mobile {
    display: none;
  }
  &-drag {
    max-width: 100%;
    border: 4px solid $bg;
    border-left: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.3s;
  }
}

@import "styles/variables.scss";

.wr {
  margin: 64px 0;
  max-width: 80%;
  @include laptop {
    margin: 5.079vw 0;
  }
  @include mobile {
    margin: 10vw 0;
  }

  display: flex;
  align-items: center;
  gap: 32px;
  @include laptop {
    gap: 2.54vw;
  }
  @include mobile {
    gap: 10vw;
    flex-direction: column;
    max-width: 100%;
  }
  &_img {
    position: relative;
    flex-shrink: 0;
    width: 44.5%;
    @include mobile {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("/assets/podcast-blur.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 110%;
      height: 110%;
      left: -5%;
      top: -7%;
      z-index: 0;
    }
    & img {
      position: relative;
      z-index: 10;
      border-radius: 16px;
    }
  }
}

.content {
  max-width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & h3 {
    @include text;
    margin-bottom: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    @include mobile {
      margin-bottom: 3.75vw;
      font-size: 3.75vw;
      line-height: 133%;
    }
  }
  & p {
    color: rgba(255, 255, 255, 0.2);
    @include text;
    margin-bottom: 20px;
    @include mobile {
      margin-bottom: 6.25vw;
      font-size: 3.75vw;
      line-height: 133%;
    }
  }
  @include mobile {
    max-width: 100%;
  }
}

.more {
  @include text;
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.3s;
  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  &:active {
    color: rgba(255, 255, 255, 0.4);
  }
  @include mobile {
    padding: 5.625vw 0;
    font-size: 3.75vw;
    line-height: 133%;
    width: 100%;
    text-transform: uppercase;
    border-radius: 12px;
    border: 0.74px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    & > span {
      display: none;
    }
  }
}

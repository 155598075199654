@import "styles/variables.scss";

.wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
}

.form {
  width: 100%;
  padding: 59px 24px 32px;
  margin-bottom: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url("/assets/form-background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @include laptop {
    padding: 1.5vw;
  }
  @include mobile {
    padding: 17px;
    margin-bottom: 20px;
  }

  & > div {
    position: relative;
    & > div {
      margin-bottom: 24px;
      width: 100%;
      @include laptop {
        margin-bottom: 2vw;
      }
      & > label {
        left: -14px;
        @include text;
        text-transform: uppercase;
        color: $text1 !important;
      }
      & > div {
        margin-top: 0;
        padding-bottom: 16px;
        padding-top: 16px;
        @include laptop {
          padding-bottom: 1vw;
          padding-top: 2vw;
        }
        @include mobile {
          padding-bottom: 8px;
          padding-top: 26px;
        }
        &::before,
        &::after {
          border-color: rgba(255, 255, 255, 0.03);
        }
        &:hover {
          &::before,
          &::after {
            border-color: rgba(255, 255, 255, 0.1) !important;
          }
        }
        & > input {
          @include text;
          text-overflow: ellipsis;
          color: $text6;
          padding: 0;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
        & > label {
          margin-bottom: 18px;
          @include laptop {
            margin-bottom: 2vw;
          }
          @include mobile {
            margin-bottom: 9px;
          }
        }
        & > div {
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          @include laptop {
            gap: 1vw;
          }
          @include mobile {
            gap: 3px;
            justify-content: flex-start;
            flex-wrap: wrap;
          }

          & > label {
            margin: 0;
            display: block;
            @include laptop {
              min-width: 20%;
            }
            & > span {
              display: flex;
              justify-content: center;
              padding: 6px 9px;
              @include text;
              border: 1px solid $text2;
              border-radius: 51px;
              color: $text2;
              white-space: nowrap;
              transition: color 0.3s, background-color 0.3s, border-color 0.3s;
              @include laptop {
                padding: 2px;
                width: 100%;
              }
              @include mobile {
                padding: 4px 8px;
                width: 100%;
              }
              &:hover {
                color: #1c1c1c;
                background-color: $text4;
                border-color: transparent;
              }
              &:first-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

button[type="submit"] {
  padding: 26px 0;
  text-transform: uppercase;
  width: 100%;
  @include laptop {
    padding: 5% 0;
  }
  @include mobile {
    padding: 25px 0;
    margin-bottom: 59px;
  }
}

.sended {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: $text5;
  z-index: 1;
  background-color: #1c1c1c;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0 28px;
  @include laptop {
    padding: 5.5vw 0 2vw;
  }
  @include mobile {
    padding: 83px 0 27px;
  }
  & > h3 {
    color: $text5;
    font-size: 44px;
    line-height: 70%;
    letter-spacing: -0.44px;
    text-transform: uppercase;
    @include jb4;
    @include laptop {
      font-size: 2.78vw;
    }
    @include mobile {
      font-size: 28px;
      line-height: 125%;
      letter-spacing: -0.56px;
    }
  }
  & > p {
    color: $text6;
    text-align: center;
    @include text;
    @include jb5;
    letter-spacing: 0.24px;
    @include mobile {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.18px;
    }
  }
}

@import "styles/variables.scss";

.slider {
  height: 100%;
  max-height: 562px;
  padding: 50px 0 63px;
  padding-left: 23px;
  @include mobile {
    padding-left: 0;
  }
  & img {
    transition: transform 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
  @include laptop {
    max-height: 50vw;
  }

  @include mobile {
    padding: 32px 0;
    overflow: visible !important;
    height: 231px;
    max-height: 100%;
  }
}

.slide_big {
  width: 57% !important;
  & > div {
    max-height: 462px;
    height: 100%;
    // overflow: hidden;
  }
  @include laptop {
    height: 35vw;
  }
  @include mobile {
    height: 167px;
  }
}
.m {
  height: auto;
}

.slide_flex {
  gap: 34px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 462px;
  width: 26.5%;
  @include laptop {
    height: 35vw;
    gap: 2.3vw;
  }
  & > div {
    max-height: 214px;
    @include laptop {
      max-height: 16.5vw;
    }
    @include mobile {
      max-height: 76px !important;
    }
  }
  @include mobile {
    gap: 13px;
    height: 167px;
  }
}
.slide_small {
  height: 462px;
  width: 29%;
  & > div {
    height: 100%;
  }
  @include laptop {
    height: 35vw;
  }
  @include mobile {
    height: 167px;
  }
}

@import "./variables.scss";
@import "./reset.min.scss";
@import "~normalize.css";

.active-link {
  opacity: 1 !important;
}

html,
body {
  min-height: 100vh;
  background-color: $bg;
  // background-color: rgb(88, 63, 88);
  color: $text6;
  overflow-x: hidden;
}

html {
  scroll-padding-top: 50px;
  scroll-behavior: smooth;
  position: relative;
}

body {
  @include jb4;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.015em;
  padding: 0px 0 1000px;
  padding-right: 0 !important;
  @include laptop {
    padding-bottom: 80vw;
  }

  @include mobile {
    padding-bottom: 757px;
    // border: 3px solid yellow;
  }

  /* Скроллбар*/
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) none;

  &::-webkit-scrollbar {
    height: 6px;
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
}

.button {
  border: 1px solid $text1;
  @include text;
  color: $text6;
  border-radius: 16px;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;
  background-color: transparent;
  &:disabled {
    cursor: auto;
    color: $text1;
    pointer-events: none;
  }
  &:hover {
    border-color: $text2;
  }
  &:active,
  &:focus {
    background-color: $text1;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

svg {
  max-width: 100%;
  max-height: 100%;
}

*:not(body, html) {
  &::selection {
    background-color: $text3;
    color: $bg;
  }
  scrollbar-color: $bg transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: $bg;
  }
}

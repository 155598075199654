@import "styles/variables.scss";

.section {
  @include mobile {
    height: auto !important;
  }
}
.video_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  @include mobile {
    height: auto;
  }
}
.video_def {
  & video {
    cursor: auto !important;
  }
}

.wr_port {
  position: relative;
  & video {
    border-radius: 16px;
    position: relative;
    z-index: 1;
    -o-object-fit: cover;
    object-fit: cover;
    background-color: black;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  & img {
    filter: blur(10px);
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    @include mobile {
      filter: none;
      -webkit-filter: none;
      -moz-filter: none;
      -o-filter: none;
      -ms-filter: none;
    }
  }

  & .label {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    // width: 390px;
    background: #151515;
    @include laptop {
      width: 28vw;
    }
    @include mobile {
      top: -31px;
    }

    & > span {
      @include text;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      // white-space: nowrap;
    }
  }
}

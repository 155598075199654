@import "styles/variables.scss";

.tab_box {
  margin-bottom: 196px;
  @include laptop {
    margin-bottom: 20vw;
  }
  @include mobile {
    margin-bottom: 83px;
  }
}

.tabs {
  & > div > div {
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px solid $text1;
    @include mobile {
      border: none;
    }
  }
  & span {
    display: none;
  }
  & .tab {
    padding: 0;
    min-width: auto;
    @include text;
    @include link-hover;
    @include jb5;
    color: $text3;
    letter-spacing: 0.01em;
    @include mobile {
      height: auto;
      min-height: auto;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  min-height: 372px;
  @include laptop {
    min-height: auto;
  }
  @include mobile {
    grid-template-columns: 1fr;
    position: relative;
    padding-bottom: 64px;
  }
  & button {
    &:active,
    &:focus {
      background-color: $text1;
    }
  }
  & > .left {
    padding-top: 36px;
    border-right: 1px solid $text1;
    padding-right: 26px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 96px;
    flex-direction: column;
    @include mobile {
      border-right: none;
      padding-top: 28px;
      padding-right: 0;
    }
    & > p {
      font-size: 44px;
      line-height: 125%;
      letter-spacing: -0.44px;
      text-transform: uppercase;
      color: $text5;
      min-height: 219px;
      max-width: 93%;
      @include laptop {
        min-height: auto;
        font-size: 2.78vw;
      }
      @include mobile {
        font-size: 18px;
        line-height: 125%;
        letter-spacing: -0.36px;
        margin-bottom: 24px;
      }
    }
    & > ul {
      display: flex;
      gap: 16px;
      letter-spacing: 0.24px;
      @include text;
      @include jb5;
      color: $text3;
      @include mobile {
        position: absolute;
        bottom: 0;
        gap: 12px;
        flex-wrap: wrap;
      }
    }
  }
  & > .right {
    padding-top: 36px;

    padding-left: 36px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @include mobile {
      padding: 0;
      gap: 24px;
    }
    & p {
      @include text;
      @include jb5;
      max-width: 50%;
      letter-spacing: 0.24px;
      @include mobile {
        max-width: 80%;
        min-height: 63px;
      }
    }
    & > div {
      align-self: flex-end;
      @include mobile {
        align-self: flex-start;
      }
    }
  }
}

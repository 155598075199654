@import "styles/variables.scss";

.box {
  display: flex;
  & > div {
    margin-right: -14px;
    transition: transform 0.3s;
    &:last-child {
      margin-right: 0;
    }
    @include mobile {
      margin-right: 3px;
    }
  }
  @for $i from 1 to 10 {
    & > div:nth-last-child(#{$i}) {
      z-index: #{$i};
    }
  }
  &:hover {
    @for $i from 1 to 10 {
      & > div:nth-last-child(#{$i}) {
        transform: translateX(calc((-25%) * #{$i}));
        @include mobile {
          transform: none;
        }
      }
    }
  }
}

@import "styles/variables.scss";

.wr {
  padding-top: 64px;
  @include mobile {
    padding-top: 40px;
  }
  & h1 {
    margin-bottom: 64px;
    opacity: 0.4;
    @include laptop {
      padding-bottom: 1%;
    }
    @include mobile {
      opacity: 1;
    }

    & > img {
      user-select: none;
      object-fit: contain;
    }
  }
  & .showreel {
    height: 732px !important;
    @include laptop {
      height: auto !important;
      aspect-ratio: 15.2/9;
    }
    & div {
      height: 100%;
    }
    & label {
      transform: translateY(0%);
      @include mobile {
        transform: translateY(-100%);
      }
    }
    & video {
      height: 732px;
      @include laptop {
        height: 55vw;
      }
      @include mobile {
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    margin-bottom: 108px;
    @include laptop {
      margin-bottom: 6vw;
    }
  }
}

.projects {
  margin-bottom: 180px;
  @include laptop {
    margin-bottom: 11vw;
  }

  & > header {
    margin-bottom: 102px;
    width: 33%;
    & > h2 {
      opacity: 0.4;
      @include mobile {
        opacity: 1;
      }
    }
    & img {
      object-fit: contain;
    }
    @include laptop {
      margin-bottom: 6vw;
    }
    @include mobile {
      width: 70%;
      margin-bottom: 54px;
    }
  }
  & > main {
    & video {
      mask-image: url("/assets/masks/mask-more.svg");
      -webkit-mask-image: url("/assets/masks/mask-more.svg");
    }
  }
}
.videos {
  margin-bottom: 154px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 36px;
  @include laptop {
    column-gap: 3vw;
    margin-bottom: 11vw;
  }
  @include mobile {
    grid-template-columns: 1fr;
    margin-bottom: 68px;
    row-gap: 96px;
  }
  & figure > div {
    width: 37%;
  }
  & > div > div > div > div {
    height: 400px !important;
    @include laptop {
      height: auto !important;
      aspect-ratio: 16/11;
    }
    @include mobile {
      aspect-ratio: 16/10;
    }
  }
  & video {
    height: 400px;
    @include laptop {
      height: 30vw;
    }
  }
}

.video_number {
  display: grid;
  grid-template-columns: 5fr 2fr;
  @include mobile {
    grid-template-columns: 1fr;
  }
  & img {
    height: auto !important;
  }
  & figure > div {
    width: 25%;
    @include mobile {
      width: 100%;
    }
  }
  & > div:last-child {
    justify-self: flex-end;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
    padding: 0 15%;
    @include mobile {
      order: -1;
      width: 100%;
      padding: 0;
      gap: 55px;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      margin-bottom: 15vw;
    }
    & > span {
      text-align: right;
      @include text;
      @include mobile {
        text-align: center;
        display: block;
        width: min-content;
      }
    }
    & > div {
      overflow: visible;
    }
  }
}

.polemica {
  & figure {
    @include mobile {
      gap: 50px;
    }
  }
  & > div:last-child {
    position: relative;
    @include mobile {
      padding-top: 12px;
      padding-left: 14px;
    }

    & img {
      height: auto;
      width: auto;
      height: 100%;
      @include mobile {
        width: 20vw;
        object-fit: contain;
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 105%;
      border: 1px solid $text1;
      border-left: none;
      @include mobile {
        bottom: initial;
        top: 0;
        width: 100%;
        height: 12px;
        border: 1px solid $text1;
        border-bottom: 0;
      }
    }
    &::after {
      @include mobile {
        content: "";
        display: block;
        position: absolute;
        left: 33%;
        width: 1px;
        height: 12px;
        color: $text1;
        background-color: $text1;
        top: 0;
      }
    }
  }
  & video {
    height: 555px;
    // height: 535px;
    -webkit-mask-position: left center !important;
    mask-position: left center !important;

    @include laptop {
      height: 40vw;
      aspect-ratio: 16/11;
    }
    @include mobile {
      aspect-ratio: 16/10 !important;
    }
  }
  & ul {
    & > li:last-child {
      @include mobile {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}

.process {
  margin-bottom: 180px;
  & figure > div > div:last-child {
    filter: none !important;
  }
  & figure > div > div:first-child {
    filter: none !important;
  }
  @include laptop {
    margin-bottom: 11vw;
  }
  @include mobile {
    margin-bottom: 0;
  }
  &_mob {
    @include mobile {
      & p {
        width: 90%;
      }
      & > div > div:last-child {
        width: 115px;
      }
    }
  }
}

.gayane {
  margin-bottom: 180px;
  border-left: 1px solid $text1;
  grid-template-columns: 2fr 5fr;
  @include laptop {
    margin-bottom: 11vw;
    & label {
      transform: none !important;
    }
  }
  @include mobile {
    grid-template-columns: 1fr;
    border-left: none;
    margin-bottom: 96px;
    & label {
      transform: translateY(-100%) !important;
    }
  }
  & video {
    -webkit-mask-position: right center !important;
    mask-position: right center !important;
    mask-image: url("/assets/masks/mask-gayane.svg");
    -webkit-mask-image: url("/assets/masks/mask-gayane.svg");
    height: 535px;
    width: 860px;

    @include laptop {
      height: 45vw;
      width: 100%;
      aspect-ratio: 16/11;
    }
  }
  & > div:first-child {
    max-width: 860px;
    justify-self: flex-end;
    order: 1;
    & figure {
      justify-content: flex-start;
      gap: 10%;
      & > div {
        display: flex;
        justify-content: space-between;
        width: 50%;
        gap: 20%;
      }
    }
  }
  & > div:last-child {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: start;
    justify-self: start;
    position: relative;
    padding-right: 0;
    padding-left: 7%;
    width: 100%;
    @include mobile {
      display: flex;
      flex-direction: row-reverse;
      padding: 0;
    }
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: $text1;
      top: 50%;
      left: 0;
      height: 1px;
      width: 60%;
      @include mobile {
        display: none;
      }
    }
    & > li {
      height: 100%;
      & > button {
        &:hover,
        &:active,
        &:focus {
          background-color: rgba(255, 255, 255, 0.03);
          cursor: auto;
        }
      }
    }
    & p {
      width: 75%;
      @include text;
      @include mobile {
        width: 60%;
        padding-left: 14px;
      }
    }
  }
  & figure {
    @include mobile {
      gap: 5px !important;
      & > div {
        flex-direction: column;
        width: 40% !important;
      }
      & > ul {
        & > li:first-child {
          word-break: break-all;
        }
      }
    }
  }
}

.dom {
  margin-bottom: 186px;
  grid-template-columns: 2fr 5fr;
  @include laptop {
    margin-bottom: 20vw;
  }
  @include mobile {
    grid-template-columns: 1fr;
    margin-bottom: 116px;
  }
  & video {
    mask-image: url("/assets/masks/mask-gayane.svg") !important;
    -webkit-mask-image: url("/assets/masks/mask-gayane.svg") !important;

    height: 535px;
    @include laptop {
      height: 42vw;
      aspect-ratio: 16/11;
    }
    @include mobile {
      margin-bottom: 21px;
      aspect-ratio: 16/9;
      height: 60vw !important;
      mask-image: url("/assets/masks/mask-mobile-2.svg") !important;
      -webkit-mask-image: url("/assets/masks/mask-mobile-2.svg") !important;
    }
  }

  & > div:first-child {
    max-width: 860px;
    justify-self: flex-end;

    order: 1;
    & figure {
      justify-content: flex-start;
      gap: 5%;
      & > div {
        display: flex;
        justify-content: space-between;
        width: 55%;
        gap: 5%;
        & > p {
          width: 50%;
        }
      }
    }
  }
  & > div:last-child {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 45%;
      height: 105%;
      border: 1px solid $text1;
      border-right: none;
      @include mobile {
        display: none;
      }
    }
    justify-self: flex-start;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    padding: 0 10%;
    @include mobile {
      order: -1;
      width: 100%;
      padding: 0 13px;
      gap: 0px;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 15vw;
    }
    & > span {
      text-align: left;
      @include text;
      @include mobile {
        display: block;
        width: 60%;
      }
    }
    & img {
      @include mobile {
        height: 35px;
        width: 66px;
        object-fit: contain;
      }
    }
  }
  & figure {
    @include mobile {
      gap: 5px !important;
      & > div {
        width: 60vw !important;
        display: block !important;
        & p:first-child {
          display: none;
        }
        & p {
          width: 80% !important;
        }
      }

      & > ul {
        & > li:first-child {
          word-break: break-all;
        }
      }
    }
  }
}

.videos_last {
  // & video {
  //   height: 403px;
  //   @include laptop {
  //     height: auto;
  //     aspect-ratio: 16/11;
  //   }
  // }
  & > div {
    &:first-child {
      & figure {
        & ul {
          order: -1;
        }
      }
    }
    &:last-child {
      @include mobile {
        margin-bottom: 22px;
      }
      & figure {
        & div {
          width: 25%;
          @include mobile {
            width: 35%;
          }
        }
      }
    }
  }
}

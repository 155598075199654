@import "styles/variables.scss";

.radius {
  border-radius: 100%;
}

.wr {
  padding-top: 58px;
  @include laptop {
    padding-top: 5vw;
  }
  @include mobile {
    padding-top: 40px;
  }
  & header {
    margin-bottom: 155px;
    @include laptop {
      margin-bottom: 10vw;
    }
    @include mobile {
      margin-bottom: 60px;
    }
    & > h1 {
      margin: 0 auto 32px;
      width: 35%;
      @include laptop {
        margin-bottom: 4vw;
      }
      @include mobile {
        width: 70%;
        margin-bottom: 20px;
      }
    }
    & > p {
      margin: 0 auto 8px;
      width: 80%;
      @include jb4;
      font-size: 44px;
      line-height: 125%;
      text-align: center;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      color: $text6;
      @include laptop {
        font-size: 2.78vw;
      }
      @include mobile {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.27px;
        width: 100%;
        margin-bottom: 2px;
      }
    }
    & > .links {
      margin-bottom: 121px;
      height: 80px;
      border: 1px solid $text1;
      border-top: none;
      @include laptop {
        height: 10vw;
        margin-bottom: 9vw;
      }
      @include mobile {
        margin-bottom: 65px;
        height: 40px;
      }
      & > ul {
        transform: translateY(50%);
        align-items: center;
        justify-content: center;
        width: 50%;
        margin: 0 auto;
        background-color: $bg;
        gap: 3px;
        @include laptop {
          transform: translateY(150%);
        }
        @include mobile {
          transform: translateY(50%);
          width: 65%;
        }
        & > div {
          margin-right: 0;
          &:first-child {
            transform: translateX(39%);
            @include mobile {
              transform: none;
            }
          }
          &:nth-child(2) {
            transform: translateX(13%);
            @include mobile {
              transform: none;
            }
          }
          &:nth-child(3) {
            transform: translateX(-13%);
            @include mobile {
              transform: none;
            }
          }
          &:last-child {
            transform: translateX(-39%);
            @include mobile {
              transform: none;
            }
          }
        }
        &:hover {
          & > div {
            transform: translateX(0);
          }
        }
      }
    }
    & > .authors {
      padding: 27px 21px;
      border-left: 1px solid $text1;
      border-right: 1px solid $text1;
      @include laptop {
        padding: 5vw 0;
      }
      @include mobile {
        padding: 0;
        border: none;
      }

      & > ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // gap: 36px;
        padding-left: 34px;
        padding-right: 34px;

        @include laptop {
          padding-left: 5vw;
          padding-right: 5vw;
        }
        @include mobile {
          gap: 8.75vw;
          flex-direction: column;
          padding-left: 0;
          padding-right: 0;
        }
        & figure {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
          gap: 24px;
          width: 17.5%;

          & > div {
            height: 100%;
            flex-shrink: 0;
            @include mobile {
              height: 22.5vw;
              width: 22.5vw;
            }
          }
          @include laptop {
            gap: 2vw;
          }
          @include mobile {
            gap: 8.438vw;
            flex-direction: row;
            align-items: center;
            width: 100%;
          }
        }
        & figcaption {
          max-width: 219px;
          @include text;
          @include jb5;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          @include laptop {
            max-width: 15vw;
          }
          @include mobile {
            font-size: 3.75vw;
            line-height: 120%;
            max-width: 100%;
            gap: 3px;
          }

          & > span:first-child {
            // margin-bottom: 4px;
            text-transform: uppercase;
          }
          & > span:last-child {
            color: $text2;
            @include mobile {
              font-size: 3.75vw;
              line-height: 110%;
              letter-spacing: 0.15px;
            }
          }
        }
      }
    }
  }
  & main {
    margin-bottom: 174px;
    @include laptop {
      margin-bottom: 15vw;
    }
    @include mobile {
      margin-bottom: 60px;
    }
    & h2 {
      @include text;
      margin-bottom: 42px;
      text-transform: uppercase;
      color: $text2;

      @include laptop {
        margin-bottom: 5vw;
      }
      @include mobile {
        margin-bottom: 32px;
      }
    }
    & .nagrady {
      display: flex;
      align-items: center;
      gap: 26px;
      height: 56px;
      max-width: 1244px;
      margin: 0 auto 42px;
      & > div {
        @include mobile {
          height: 56px;
          & > div > div {
            width: 82px;
          }
        }
      }
      & a {
        display: block;
        padding: 7.5px;
        transition: background-color 0.3s;
        border-radius: 9px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.06);
        }
        &:active,
        &:focus {
          background-color: $text1;
        }
        @include mobile {
          padding: 9px;
          border-radius: 3px;
          width: 82px;
        }
      }
      @include laptop {
        height: 5vw;
        margin-bottom: 5vw;
        max-width: 95%;
      }
      @include mobile {
        margin-bottom: 24px;
        height: 61px;
        gap: 3px;
        max-width: 100%;
      }
    }
    & .nominacyy {
      border-top: 1px solid $text1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      & > a {
        width: 100%;
      }

      & li {
        padding: 15px 4px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $text1;
        transition: background-color 0.3s;
        &:hover {
          background-color: rgba(255, 255, 255, 0.03);
        }
        &:active,
        &:focus {
          background-color: $text1;
        }
        @include laptop {
          padding: 1vw 1px;
        }
        @include mobile {
          padding: 20px 0;
          display: grid;
          grid-template-columns: 1fr 64px;
          grid-template-rows: auto minmax(34px, auto);
          gap: 8px 22px;
        }
        & > div {
          height: 34px;
          width: auto;
          aspect-ratio: 16/9;
          @include laptop {
            height: 5vw;
          }
          @include mobile {
            grid-row: 2;
            height: 34px;
          }
          & > div:last-child {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            filter: blur(15px);
            z-index: -1;
          }
          & img {
            object-fit: contain;
          }
        }
        & span {
          margin-right: 32px;
          @include text;
          @include link-hover;
          color: $text4;
          @include laptop {
            margin-right: 3vw;
          }
        }
        & p {
          margin-right: 24px;
          @include text;
          color: $text2;
          @include laptop {
            margin-right: 2vw;
          }
          @include mobile {
            grid-row: 2;
          }
        }
      }
    }
    & .more {
      padding: 20px 0;
      width: 100%;
      @include text;
      @include link-hover;
      color: $text2;
      text-transform: uppercase;
      border-bottom: 1px solid $text1;
      &:disabled {
        display: none;
      }
      @include mobile {
        padding: 32px 0;
      }
    }
  }
  & footer {
    border-left: 1px solid $text1;
    border-right: 1px solid $text1;
    // padding-left: 23px;
    // padding-bottom: 23px;

    @include mobile {
      border: none;
      padding: 0;
      margin-bottom: 40px;
    }

    & > h2 {
      @include text;
      color: $text2;
      padding-left: 23px;

      text-transform: uppercase;
      @include mobile {
        padding: 0;
      }
    }
  }
}

.au2 {
  & figcaption {
    max-width: 168px !important;
    @include laptop {
      max-width: 10vw !important;
    }
    @include mobile {
      max-width: auto;
    }
  }
}

@import "styles/variables.scss";

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  overflow-x: hidden;
}

.wrapper {
  & .head {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    border-top: 1px solid $text1;
    margin-bottom: 5%;
    @include mobile {
      grid-template-columns: 2fr 1fr;
      margin-bottom: 36px;
    }
    & > div {
      padding-top: 29px;
      display: flex;
      // align-items: flex-end;
      justify-content: center;
      @include laptop {
        padding-top: 2vw;
      }
      @include mobile {
        padding-top: 17px;
      }
      &:nth-child(2) {
        border-right: 1px solid $text1;
        border-left: 1px solid $text1;
        @include mobile {
          border-left: none;
          justify-content: flex-start;
        }
      }
      &:first-child {
        justify-content: flex-start;
        @include mobile {
          display: none;
        }
      }
    }
    & h3 {
      @include jb4;
      font-size: 44px;
      line-height: 125%;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      @include laptop {
        font-size: 2.78vw;
      }
    }
    & a {
      @include link-hover;
      @include text;
      display: flex;
      align-items: center;
      & > span {
        color: $text4;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
      }
    }
    & button {
      & div {
        @include link-hover;
        @include text;
        display: flex;
        align-items: center;
        & > span {
          color: $text4;
          margin-right: 8px;
          display: inline-flex;
          align-items: center;
        }
      }
      &:hover {
        & span {
          &:first-child {
            transform: translateX(-5px);
          }
          &:last-child {
            transform: translateX(5px);
          }
        }
      }
      & > div > span {
        display: inline-block;
        transition: transform 0.3s;
        &:first-child {
          margin-right: 7px;
        }
        &:last-child {
          margin-left: 7px;
        }
      }
    }
  }
  & .content {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    margin-bottom: 5%;
    @include mobile {
      margin-bottom: 0;
      grid-template-columns: 1fr;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        justify-content: flex-start;
        @include mobile {
          display: block;
          & > div {
            width: 100%;
          }
        }
      }
    }
    & .authors {
      align-items: flex-start;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 50px 5%;
      gap: 10%;
      @include laptop {
        padding: 10% 5%;
      }
      @include mobile {
        display: none;
      }
      & > article {
        & > div {
          margin-bottom: 24px;
          max-width: 80px;
          // max-height: 72px;
          aspect-ratio: 1/1;
          & > img {
            object-fit: contain;
          }
          @include laptop {
            max-width: 5vw;
            margin-bottom: 2vw;
          }
        }
        & p {
          @include text;
          margin-bottom: 65px;
          @include laptop {
            margin-bottom: 5vw;
          }
        }
        & a {
          @include text;
          @include link-hover;
          text-decoration-line: underline;
          color: $text3;
        }
      }
    }
    & .policy {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 154px;
      @include laptop {
        padding-top: 60%;
      }
      @include mobile {
        padding: 0;
        flex-direction: row;
      }

      & > div > a {
        display: block;
        width: 75%;
        @include link-hover;
        @include text;
        text-decoration-line: underline;
        color: $text3;
        margin-left: 17%;
        @include mobile {
          margin-left: 0;
          width: 30%;
        }
      }
      & .social {
        align-self: flex-end;
      }
    }
  }
  & .foot {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    & img {
      @include laptop {
        width: 10vw;
      }
      @include mobile {
        width: 112px;
      }
    }
    & > div:first-child {
      & > a {
        display: block;
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.85;
        }
      }
    }
    & > div {
      padding: 35px 0;
      @include mobile {
        padding: 10px 0;
      }
      & > span {
        color: $text3;
        @include text;
      }
    }
    & .designed {
      width: 40%;
      text-align: center;
      background-image: url("/assets/footer-background.png");
      background-position: bottom center;
      background-repeat: no-repeat;
      @include laptop {
        background-size: contain;
      }
      @include mobile {
        margin-top: 130px;
        width: 100%;
      }
      & > a {
        @include text;
        @include link-hover;
        font-size: 12px;
        line-height: 167%;
        color: $text1;
        text-transform: uppercase;
        @include laptop {
          font-size: 0.83vw;
        }
        @include mobile {
          font-size: 8px;
          @include jb5;
          line-height: 20px;
          letter-spacing: 0.12px;
        }
      }
    }
    & > div:first-child,
    & > div:last-child {
      @include mobile {
        position: absolute;
        bottom: 69px;
        left: 0;
      }
    }
    & > div:last-child {
      @include mobile {
        right: 0;
        left: initial;
      }
    }
  }
}
